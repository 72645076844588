export function getMapIndicators() {
  const mapIndicators = [
    {
      targetId: "map-kanada",
      instrument: 83385,
      flagClass: "millistream-flag-kanada",
      custom_name: "Kanada",
    },
    {
      targetId: "map-dowjones",
      instrument: 39485,
      flagClass: "millistream-flag-usa",
      custom_name: "Dow Jones",
    },
    {
      targetId: "map-mexiko",
      instrument: 83651,
      flagClass: "millistream-flag-mexiko",
      custom_name: "Mexiko",
    },
    // {
    //     targetId: "map-brasilien",
    //     instrument: 86237,
    //     flagClass: "millistream-flag-brasilien",
    //     custom_name: "Brasilien",
    // },
    {
      targetId: "map-london",
      instrument: 86347,
      flagClass: "millistream-flag-storbritanien",
      custom_name: "London",
    },
    {
      targetId: "map-frankfurt",
      instrument: 83525,
      flagClass: "millistream-flag-tyskland",
      custom_name: "Frankfurt",
    },
    {
      targetId: "map-sydafrika",
      instrument: 86052,
      flagClass: "millistream-flag-sydafrika",
      custom_name: "Sydafrika",
    },
    {
      targetId: "map-moskva",
      instrument: 1100379,
      flagClass: "millistream-flag-ryssland",
      custom_name: "Moskva",
    },
    {
      targetId: "map-tokyo",
      instrument: 42054,
      flagClass: "millistream-flag-japan",
      custom_name: "Tokyo",
    },
    {
      targetId: "map-sydkorea",
      instrument: 83599,
      flagClass: "millistream-flag-sydkorea",
      custom_name: "Sydkorea",
    },
    {
      targetId: "map-hongkong",
      instrument: 83599,
      flagClass: "millistream-flag-kina",
      custom_name: "Hong Kong",
    },
    {
      targetId: "map-australien",
      instrument: 86289,
      flagClass: "millistream-flag-australien",
      custom_name: "Australien",
    },
  ];
  return mapIndicators;
}
